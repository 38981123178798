/*eslint-disable*/
import React from "react"
import { Link } from "react-router-dom"
// components

import IndexDropdown from "components/Dropdowns/IndexDropdown.js"

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false)
  return (
    <>
      <div className="container  mx-auto">
        <div className="flex justify-between">
          <span className=" h-full inline-flex items-center">
            <img alt="talenty" src={require("assets/img/talenty_logo_200x110.png")} />
          </span>
          <Link to="/auth/login" className="w-16 h-14 text-sm inline-flex items-center justify-center">
            <span className="cursor-pointer text-xl lg:hidden">
              <i className="fa fa-user"></i>
            </span>
          </Link>

          <div className="h-14 flex inline-flex items-center float-right hidden lg:block mt-4">
            <Link to="/auth/login" className="text-indigo-600 font-bold">
              <i className="fas fa-user"></i> login
            </Link>

            <Link to="/auth/register" className="bg-rose-600 text-white  text-xs font-bold  px-4 py-2 rounded ml-3">
              <i className="fas fa-pencil"></i> registrarme
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
