import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function HeaderJobs({ title, description }) {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <link rel="preload" as="image" href="/assets/bgblue-lg.webp" type="image/png" />
        </Helmet>
      </HelmetProvider>

      {/* Contenedor alineado con ViewJob */}
      <div className="relative mx-auto lg:w-4/5 sm:w-3/5 py-12 sm:py-14 rounded-b-lg shadow-md">
        {/* Imagen de fondo */}
        <div className="absolute inset-0 -z-10">
          <img
            alt="talenty-bg"
            srcSet="/assets/bgblue-sm.jpeg 768w, /assets/bgblue-lg.webp 1024w"
            sizes="(max-width: 1023px) 100vw, 1024px"
            src="/assets/bgblue-lg.webp"
            loading="eager"
            className="h-full w-full object-cover object-center rounded-b-lg"
          />
          {/* Capa oscura para mejor contraste 
          <div className="absolute inset-0 bg-gray-900 bg-opacity-10 rounded-b-lg" /> */}
        </div>

        {/* Contenido */}
        <div className="relative px-6 lg:px-8 text-center text-white">
          <h1 className="text-3xl sm:text-4xl font-bold tracking-tight">{title}</h1>
          <p className="mt-4 text-lg">{description}</p>
        </div>
      </div>
    </>
  );
}
