import React, { useEffect, useState, useRef, Fragment } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"

import HeaderJobs from "components/Headers/HeaderJobs"
import FooterJobs from "components/Footers/FooterJobs"
import { useParams, Link } from "react-router-dom"
import { MapIcon, BriefcaseIcon, CurrencyDollarIcon, PlusIcon } from "@heroicons/react/24/outline"



import { Transition, Dialog } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"


import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik"
import * as Yup from "yup"
import Alert from "components/Alerts/Alert"
import Loader from "components/Loaders/Loader"
import { EditorState, ContentState, convertFromHTML } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import PostulacionStorePublic from "views/jobs/services/PostulacionStorePublic"
import ShowVacante from "views/jobs/services/ShowVacante"


import AlertHeadless from "components/Alerts/AlertHeadless"

export default function ViewJob() {
  const params = useParams()
  const [vacanteData, setVacanteData] = useState([])
  const [jobPosting, setJobPosting] = useState()
  const [showModal, setShowModal] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [loading, setLoading] = useState(true)
  const [showAlertErrors, setShowAlertErrors] = useState(false)
  const [showAlertHeadless, setShowAlertHeadless] = useState(false)
  const [errors, setErrors] = useState({})

  //const [curriculumField, curriculumMeta] = useField("curriculum");

  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const formRef = useRef(null)

  useEffect(() => {
    async function getVacante() {
      try {
        const vacante = await ShowVacante(params.vacanteId)
        vacante.success ? setVacanteData(vacante.data) : console.log(vacante.message)
        //const dom = htmlToDOM(vacante.data.description);
        //setReactComponents( domToReact(dom)) ;
        if (vacante.data.description) {
          const contentState = ContentState.createFromBlockArray(convertFromHTML(vacante.data.description))
          const editorContentState = EditorState.createWithContent(contentState)
          setEditorState(editorContentState)
        }

        if (vacante.success) {
          const datos = vacante.data

          let googleJson = {
            "@context": "https://schema.org/",
            "@type": "JobPosting",
            title: `${datos.title}`,
            description: `${datos.description}`,
            identifier: {
              "@type": "PropertyValue",
              name: "Talenty",
              value: "5440064",
            },
            datePosted: `${datos.datePosted}`,
            validThrough: `${datos.validThrough}`,
            employmentType: `${datos.employmentType}`,
            hiringOrganization: {
              "@type": "Organization",
              name: `${datos.hiringOrganization}`,
              sameAs: "https://talenty.com.mx",
              logo: "https://talenty-api.fly.dev/imgs/talentylogo.png",
            },
            jobLocation: {
              "@type": "Place",
              address: {
                "@type": "PostalAddress",
                streetAddress: `${datos.streetAddress}`,
                addressLocality: `${datos.addressLocality}`,
                addressRegion: `${datos.addressRegion}`,
                postalCode: `${datos.postalCode}`,
                addressCountry: `${datos.addressCountry}`,
              },
            },
            baseSalary: {
              "@type": "MonetaryAmount",
              currency: `${datos.baseSalaryCurrency}`,
              value: {
                "@type": "QuantitativeValue",
                value: parseFloat(datos.baseSalaryValue),
                unitText: `${datos.baseSalaryUnitText}`,
              },
            },
          }
          setJobPosting(JSON.stringify(googleJson))
          setLoading(false)
        }
      } catch (errors) {
        console.log(errors)
      }
    }
    getVacante()
  }, [params.vacanteId])

  function onCancelModal() {
    setShowModal(false)
    setProcessing(false)
  }



  function onCancelAlert() {
    setShowAlertHeadless(false)
  }

  return (
    <>
       

      {loading ? (
        <HelmetProvider>
          <Helmet>
            <title>
              Cargando empleo...
            </title>
          
          
      
          </Helmet>
        </HelmetProvider>
      ):
      <HelmetProvider>
          <Helmet>
            <title>
              Empleo de {vacanteData.title} en {vacanteData.addressLocality}{" "}
            </title>
            <meta name="description" content={vacanteData.title} />
            <script type="application/ld+json">{jobPosting}</script>
            <link rel="canonical" href={"https://talenty.com.mx/empleo/" + vacanteData.id} />
          </Helmet>
        </HelmetProvider>
      
      }

     

       <HeaderJobs
          title={loading ? <Loader text="Cargando..." />: "Vacante: " + vacanteData.title}
          description={loading ? <Loader text="..." />  : "$" + vacanteData.baseSalaryValue + " | " + vacanteData.addressLocality}
        />


      

<div className="flex justify-center h-auto p-4">
  <div className="bg-white rounded-lg shadow-md p-6 lg:w-4/5 sm:w-3/5 mx-2">
    <div className="grid grid-cols-1 sm:grid-cols-4 gap-6">
      {/* Lado izquierdo (Información + botón de aplicar) */}
      <div className="sm:col-span-1">
        <button
          className="w-full bg-indigo-600 text-white py-3 rounded-md text-sm font-semibold shadow-md hover:bg-indigo-700 transition duration-300"
          id="aplicarahorabutton"
          onClick={() => setShowModal(true)}
        >
          Aplicar ahora
        </button>

        <div className="mt-6 space-y-4 text-gray-700 text-sm">
          {loading ? (
            <Loader text="Cargando..." />
          ) : (
            <p className="flex items-center font-medium">
              <BriefcaseIcon className="w-5 h-5 text-gray-400 mr-2" /> {vacanteData.hiringOrganization}
            </p>
          )}

          <p className="flex items-center font-medium">
            <MapIcon className="w-5 h-5 text-gray-400 mr-2" /> {vacanteData.addressLocality}
          </p>

          <p className="flex items-center font-medium">
            <CurrencyDollarIcon className="w-5 h-5 text-gray-400 mr-2" />
            {vacanteData.baseSalaryValue} {vacanteData.baseSalaryCurrency}
          </p>
        </div>

        {/* Botón "Más empleos" */}
        <div className="mt-6">
          <Link to="/empleos/">
            <button
              className="w-full flex items-center justify-center rounded-md bg-indigo-50 px-3 py-2 text-sm font-medium text-indigo-700 ring-1 ring-indigo-700/10 hover:bg-indigo-100 transition"
              id="masempleosbutton"
            >
              <PlusIcon className="w-5 h-5 text-indigo-700 mr-2" />
              Más empleos
            </button>
          </Link>
        </div>
      </div>

      {/* Lado derecho (Descripción de la vacante) */}
      <div className="sm:col-span-3">
        <h2 className="text-2xl font-bold text-gray-900 border-b pb-3 mb-4">Descripción de la vacante</h2>
        {loading ? <Loader /> : <Editor editorState={editorState} toolbarHidden={true} />}
      </div>
    </div>
  </div>
</div>


      <AlertHeadless
        showAlert={showAlertHeadless}
        titleAlert="Postulación exitosa"
        cancelAlert={onCancelAlert}
        setShowAlert={setShowAlertHeadless}
      >
        <div className="mt-2">
          <p className="text-sm text-gray-500">Te has postulado correctamente. En breve nos pondremos en contacto.</p>
          <br></br>
          <p className="text-sm text-blue-600">¡Éxito!</p>
        </div>
      </AlertHeadless>
   

      {/* start modal postulacion  */}
      <Transition.Root show={showModal} as={Fragment}>
  <Dialog as="div" className="relative z-20" initialFocus={onCancelModal} onClose={setShowModal}>
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 transition-opacity" />
    </Transition.Child>

    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <Dialog.Panel className="relative w-full max-w-2xl transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full">
            {/* Header */}
            <div className="flex items-center justify-between p-5 bg-indigo-600 text-white">
              <h2 className="text-xl font-bold">Postularse</h2>
              <button
                type="button"
                className="rounded-full p-2 hover:bg-indigo-500 transition"
                onClick={() => setShowModal(false)}
              >
                <XMarkIcon className="w-6 h-6" />
              </button>
            </div>

            {/* Contenido */}
            <div className="p-6">
              {/* Mensajes de error */}
              <Alert type="warning" show={showAlertErrors} onClose={() => setShowAlertErrors(false)}>
                {Object.keys(errors).length > 0 && (
                  <div className="mb-4">
                    <p className="text-black font-medium">No se pudo enviar tu postulación.</p>
                    <ul className="mt-2 list-disc list-inside text-sm text-black">
                      {Object.keys(errors).map((key, index) => (
                        <li key={index} className="flex items-center gap-2">
                          ❌ {errors[key]}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </Alert>

              <Formik
                innerRef={formRef}
                initialValues={{
                  nombre: "",
                  email: "",
                  telefono: "",
                  fuente_postulacion: "Talenty Jobs",
                  descripcion: "",
                  curriculum: null,
                  job_id: params.vacanteId,
                  empresa_id: vacanteData.empresa_id,
                }}
                validationSchema={Yup.object({
                  nombre: Yup.string().required("El campo nombre es requerido"),
                  email: Yup.string().email("Email inválido: example@correo.com").required("El campo email es requerido"),
                  telefono: Yup.string().required("El campo teléfono es requerido"),
                  descripcion: Yup.string().required("El campo descripción es requerido"),
                })}
                onSubmit={async (values) => {
                  try {
                    setProcessing(true);
                    const response = await PostulacionStorePublic(values);
                    if (response.success) {
                      setProcessing(false);
                      setShowModal(false);
                      setShowAlertHeadless(true);
                    } else {
                      setProcessing(false);
                      setShowAlertErrors(true);
                      setErrors("No se pudo guardar la postulación");
                    }
                  } catch (errors) {
                    setProcessing(false);
                    setShowAlertErrors(true);
                    setErrors(errors);
                  }
                }}
                enctype="multipart/form-data"
              >
                {() => (
                  <Form className="space-y-4">
                    {/* Campos */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      {/* Nombre */}
                      <div>
                        <label htmlFor="nombre" className="block text-sm font-medium text-gray-700">
                          Nombre <span className="text-red-500">*</span>
                        </label>
                        <Field
                          name="nombre"
                          type="text"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        <ErrorMessage name="nombre" component="p" className="text-xs text-red-500 mt-1" />
                      </div>

                      {/* Email */}
                      <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                          Email <span className="text-red-500">*</span>
                        </label>
                        <Field
                          name="email"
                          type="email"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        <ErrorMessage name="email" component="p" className="text-xs text-red-500 mt-1" />
                      </div>

                      {/* Teléfono */}
                      <div>
                        <label htmlFor="telefono" className="block text-sm font-medium text-gray-700">
                          Teléfono <span className="text-red-500">*</span>
                        </label>
                        <Field
                          name="telefono"
                          type="text"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        <ErrorMessage name="telefono" component="p" className="text-xs text-red-500 mt-1" />
                      </div>
                    </div>

                    {/* Descripción */}
                    <div>
                      <label htmlFor="descripcion" className="block text-sm font-medium text-gray-700">
                        Cuéntanos sobre ti <span className="text-red-500">*</span>
                      </label>
                      <Field
                        name="descripcion"
                        as="textarea"
                        placeholder="Explica por qué eres un buen candidato para esta vacante."
                        className="mt-1 block w-full h-28 rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                      <ErrorMessage name="descripcion" component="p" className="text-xs text-red-500 mt-1" />
                    </div>

                    {/* Subir CV */}
                    <CurriculumUpload />

                    {/* Botones */}
                    <div className="flex justify-end space-x-3 mt-4">
                      <button
                        type="button"
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 transition"
                        onClick={() => setShowModal(false)}
                      >
                        Cancelar
                      </button>
                      <button
                        type="submit"
                        className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 transition"
                        disabled={processing}
                      >
                        {processing ? <Loader text="Enviando..." /> : "Enviar"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </div>
  </Dialog>
</Transition.Root>

        {/** end modal postulacion */}


     <FooterJobs /> 
    </>
  )
}

function CurriculumUpload() {
  const { setFieldValue } = useFormikContext()
  const [uploadedFile, setUploadedFile] = useState(null)
  const fileInputRef = useRef(null)

  const handleDrop = (event) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    setFieldValue("curriculum", file)
    setUploadedFile(file)
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setFieldValue("curriculum", file)
    setUploadedFile(file)
  }

  const handleRemoveFile = () => {
    setFieldValue("curriculum", null)
    setUploadedFile(null)
  }

  const handleChooseFile = () => {
    fileInputRef.current.click()
  }

  return (
    <div>
      <label htmlFor="curriculum" className="block text-sm font-medium text-gray-700">
        Curriculum (opcional)
      </label>
      {!uploadedFile ? (
        <div
          className="mt-1 border-2 border-dashed border-gray-300 rounded-md p-4 cursor-pointer"
          onDrop={handleDrop}
          onDragOver={(event) => {
            event.preventDefault()
          }}
          onClick={handleChooseFile}
        >
          <input ref={fileInputRef} name="curriculum" type="file" className="hidden" onChange={handleFileChange} />
          <div className="text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M20 4h8M20 12h8M4 12h8m-4 4v24a4 4 0 004 4h24a4 4 0 004-4V16a4 4 0 00-4-4h-8m-4 8h8m-4 0v12m-4-12H12v12H6V16zm4 4h8v8h-8v-8z"
              />
            </svg>
            <div className="mt-2">
              <div className="text-sm text-gray-600">
                <label
                  htmlFor="curriculum"
                  className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out"
                >
                  Haz clic aquí
                </label>{" "}
                o arrastra y suelta un archivo
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center mt-1">
          <svg
            className="mr-2 h-5 w-5 text-gray-400 cursor-pointer"
            fill="currentColor"
            viewBox="0 0 20 20"
            onClick={handleRemoveFile}
          >
            <path
              fillRule="evenodd"
              d="M14.121 5.879a1 1 0 010 1.414L7.414 12l6.707 6.707a1 1 0 11-1.414 1.414L6 13.414l-6.707 6.707a1 1 0 11-1.414-1.414L4.586 12 .879 8.293a1 1 0 111.414-1.414L6 10.586l3.707-3.707a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
          <span className="truncate">{uploadedFile.name}</span>
        </div>
      )}
      {uploadedFile && (
        <small className="text-xs text-red-500">
          <ErrorMessage name="curriculum" />
        </small>
      )}
    </div>
  )
}
